// Avoid `console` errors in browsers that lack a console.
'use strict';
jQuery(document).ready(function($){
  
  var w     = $(window).width(),
  toggle 		= $('#toggle-menu'),
  menu 		  = $('nav ul'),
  hasChild  = $('.menu-item-has-children'),
  dropdown  = $('.sub-menu');

$(function() {
  $(toggle).on('click', function(e) {
    e.preventDefault();
    menu.toggle();
  });
  
  $(hasChild).click(function(e) {
    e.preventDefault();
    dropdown.toggle();
  });
});

$(window).resize(function(){
  if(w > 320 && menu.is(':hidden')) {
    menu.removeAttr('style');}
});
 

$('#product-selector   a').on('click, mouseover', function(e) {
    e.preventDefault();
    $('.product-tab').hide()
    $('#product-selector a').removeClass( "active-brand" );
    var tab = $(this).data('id');
    $(this).addClass( "active-brand" )
    $('.' +  tab).show();
    
    console.log();
});

$('.product-tab:first').show();
$('#product-selector a:first').addClass( "active-brand" );


  /* $('.owl-carousel').owlCarousel({
    loop:true,
    margin:10,
    nav:false,
    dots: true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:3
        },
        1000:{
            items:4
        }
    }
})
*/

});